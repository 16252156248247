import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/patient/all",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/blank",
          component: () => import("@/view/pages/Blank.vue")
        }
      ]
    },
    {
      path: "/patient",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "dashboard",
          path: "/patient/all",
          component: () => import("@/view/pages/PatientsDashboard.vue")
        },
        {
          path: "/patient/:id",
          component: () => import("@/view/pages/Patient.vue")
        }
      ]
    },
    {
      path: "/disease",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/disease/:id",
          component: () => import("@/view/pages/Disease.vue")
        }
      ]
    },
    {
      path: "/study",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/study/:id",
          component: () => import("@/view/pages/Study.vue")
        }
      ]
    },
    {
      path: "/group",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/group/:id",
          component: () => import("@/view/pages/Group.vue")
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/pages/auth/Login")
    },
    {
      // the 404 route, when none of the above matches
      path: "*",
      redirect: "/404"
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/Error.vue")
    }
  ]
});
